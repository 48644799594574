<template>
  <div class="page">
    <div style="background: #fff;"
         class="pt-2 pb-2 pl-4 color-warning"
         @click="toTest">去新增页</div>
    <div style="background: #fff;"
         class="pt-2 pb-2 pl-4 color-warning"
         @click="clearAll()">全部清空</div>
    <div class="p-footer h-end">
      <van-calendar title="日历"
                    :poppable="false"
                    :show-confirm="false"
                    :min-date="minDate"
                    :style="{ height: '500px' }"
                    @select="onConfirm" />

    </div>
    <div class="s2 p-card fs-16 m-3 pt-2 pb-2 pl-2 pb-2"
         v-for="(list,indexs) in content"
         :key="list">
      <div class="color-primary flex">描述：{{list[0].content}} <div class="flex-right mr-2"
             @click="deleteList(list[0].id)">删除</div>
      </div>
      <div v-for="(el,index) in list"
           class="mt-2 flex"
           :key="el">
        第{{index+1}}次 ：{{el.value}} — {{el.key}}
        <van-checkbox v-model="el.checked"
                      class="flex-right mr-2"
                      @change="changeSet(indexs, list)"></van-checkbox>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Calendar } from 'vant';
export default {
  name: 'WelcomeWords',
  components: { Calendar },
  data () {
    return {
      status: false,
      chatroomList: [],
      contentMsgs: [],
      list: [],
      content: [],
      minDate: new Date(2021, 5, 1),
      nowTime: ""
    }
  },
  computed: {
    ...mapGetters(['globalData'])
  },
  created () {
    // this.toIndex();
    this.getTime();
    this.getDate();
  },
  methods: {
    getDate () {
      this.content = [];
      if (localStorage.getItem(("time" + this.nowTime))) {
        let content = localStorage.getItem(("time" + this.nowTime)).split(",");
        let value = [];
        content.map(item => {
          if (localStorage.getItem(item)) {
            value.push(JSON.parse(localStorage.getItem(item)));
          }
        })
        this.content = value;
      }
    },
    getTime () {

      var myDate = new Date();

      var year = myDate.getFullYear();

      var month = myDate.getMonth() + 1;

      var date = myDate.getDate();

      var now = year + '-' + this.getMakeZero(month) + "-" + this.getMakeZero(date);

      this.nowTime = now;

      return now;
    },
    toIndex () {
      this.list = [];
      // 五分钟
      let value = [
        { key: '5分钟', value: 1000 * 60 * 5 },
        { key: '30分钟', value: 1000 * 60 * 30 },
        { key: '12小时', value: 1000 * 60 * 60 * 12 },
        { key: '1天', value: 1000 * 60 * 60 * 24 },
        { key: '2天', value: 1000 * 60 * 60 * 24 * 2 },
        { key: '4天', value: 1000 * 60 * 60 * 24 * 4 },
        { key: '7天', value: 1000 * 60 * 60 * 24 * 7 },
        { key: '15天', value: 1000 * 60 * 60 * 24 * 15 }]
      value.map(item => {
        this.list.push(this.sysTime(item));
      })
      // this.list.push(new Date((new Date()).getTime() + 1000 * 60 * 60 * 24))

    },

    sysTime (item) {

      var myDate = new Date((new Date()).getTime() + item.value);

      var year = myDate.getFullYear();

      var month = myDate.getMonth() + 1;

      var date = myDate.getDate();

      var h = myDate.getHours();

      var m = myDate.getMinutes();

      var s = myDate.getSeconds();

      var now = year + '-' + this.getMakeZero(month) + "-" + this.getMakeZero(date) + " " + this.getMakeZero(h) + ':' + this.getMakeZero(m);

      return { key: item.key, value: now };
    },
    getMakeZero (s) {
      return s < 10 ? '0' + s : s;
    },
    onConfirm (date) {
      this.nowTime = date.getFullYear() + "-" + this.getMakeZero((parseInt(date.getMonth()) + 1)) + "-" + this.getMakeZero(date.getDate());
      this.getDate();
    },
    changeSet (indexs, list) {
      let content = localStorage.getItem(("time" + this.nowTime)).split(",");
      let value = content[indexs];
      localStorage.setItem(value, JSON.stringify(list))
    },
    toTest () {
      this.$router.push({ path: '/keywords/test' })
    },
    deleteList (id) {
      console.log(id)
      localStorage.removeItem("idValue"+id)
      this.getDate();
      this.$Toast("删除成功")
    },
    clearAll() {
      localStorage.clear()
      this.getDate()
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";
</style>
